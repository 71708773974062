<template>
	<div class="wizard-features">
		<div
			v-for="{
				title, type: itemType, image, subtitle
			} in selectableItems"
			:key="title"
			v-qa="`wizard-features-card-${title}`"
			class="wizard-features__feature"
			:class="{ 'wizard-features__feature--active': value.includes(itemType) }"
			@click="toggleItem(itemType)"
		>
			<ZyroCheckbox
				class="wizard-features__checkbox"
				:checked="value.includes(itemType)"
				@input="toggleItem(itemType)"
			/>
			<div class="wizard-features__feature-ilustration">
				<img
					class="wizard-features__image"
					:class="{ 'wizard-features__image--online-store': itemType === 'online-store' }"
					:src="image && require(`@/assets/images/${image}`)"
				>
			</div>
			<div class="wizard-features__feature-info">
				<div class="wizard-features__feature-title z-body-small z-font-weight-bold">
					{{ title }}
				</div>
				<div class="wizard-features__feature-subtitle z-body-small">
					{{ subtitle }}
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import EventLogApi from '@/api/EventLogApi';
import {
	GENERATOR_ITEMS,
	ADD_CONDITIONS,
} from '@/pages/wizard/data/features';
import {
	DEFAULT_VALUES,
	PROPERTIES,
} from '@/pages/wizard/data/steps';

export default {
	props: {
		value: {
			type: Array,
			required: true,
		},
	},
	computed: {
		selectableItems() {
			return GENERATOR_ITEMS.filter((item) => item.addCondition === ADD_CONDITIONS.IF_SELECTED)
				.sort((a, b) => a.selectOrder - b.selectOrder);
		},
	},
	methods: {
		toggleItem(item) {
			const isSelected = this.value.includes(item);
			const newSelectedItems = isSelected
				? this.value.filter((selectedItem) => selectedItem !== item) : [
					...this.value,
					item,
				];

			this.$emit('input', newSelectedItems);

			if (!isSelected) {
				this.logItemSelect({
					itemName: item,
					isSelected: true,
				});
			} else if (isSelected && DEFAULT_VALUES[PROPERTIES.SELECTED_ITEMS].includes(item)) {
				this.logItemSelect({ itemName: item });
			}
		},
		logItemSelect({
			itemName,
			isSelected,
		}) {
			EventLogApi.logEvent({
				eventName: `qa_wizard.website_functions.${isSelected ? 'select' : 'unselect'}_function`,
				eventProperties: { function_name: itemName },
			});
		},
	},
	GENERATOR_ITEMS,
};
</script>

<style lang="scss" scoped>
.wizard-features {
	$this: &;

	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	max-width: 1024px;
	padding-top: 46px;

	&__feature {
		position: relative;
		display: flex;
		flex-direction: column;
		width: 488px;
		height: 248px;
		margin: 16px;
		margin-bottom: 32px;
		overflow: hidden;
		border: 1px solid $grey-200;
		border-radius: 5px;

		@media screen and (max-width: $media-mobile) {
			width: 90%;
			margin-right: 0;
		}

		&--active {
			border: 1px solid $primary;
		}

		&:hover {
			cursor: pointer;
			border: 1px solid $primary;
		}

		&:nth-of-type(2n) {
			margin-right: 0;
		}
	}

	&__checkbox {
		position: absolute;
		top: 8px;
		left: 8px;
		z-index: 1;
	}

	&__feature-ilustration {
		display: flex;
		align-items: flex-end;
		justify-content: center;
		width: 100%;
		height: 140px;
		background: $grey-400;
		animation: background 0.3s;

		#{$this}__feature--active & {
			background: $accent-one-lighter;
		}
	}

	&__image {
		width: 326px;

		&--online-store {
			width: 354px;
			transform: translateX(14px);
		}
	}

	&__feature-info {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		align-items: center;
		justify-content: center;
	}

	&__feature-title {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 8px;
	}
}
</style>
