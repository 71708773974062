<template>
	<div class="category">
		<ZyroFieldText
			input-theme="secondary"
			class="category__input"
			data-qa="wizard-websitecategory-inputfield-category"
			autofocus
			focus-on-mount
			:value="value"
			@input="handleInput($event.target.value)"
			@keydown.up.prevent="setActiveSuggestion(ARROW_UP)"
			@keydown.down.prevent="setActiveSuggestion(ARROW_DOWN)"
			@keydown.enter="selectActiveSuggestion"
		/>
		<div
			ref="suggestions"
			class="category__suggestions"
		>
			<div
				v-if="value"
				:ref="INPUT_VALUE_SUGGESTION_REF"
				class="category__suggestions-category z-body-small"
				:class="{ 'category__suggestions-category--active': activeSuggestionIndex === 0 }"
				@click="selectCategorySuggestion(value)"
				v-text="value"
			/>
			<div v-show="!!categorySuggestions.length">
				<div class="category__suggestions-title">
					<p
						class="z-button-small"
						v-text="$t('common.suggestions')"
					/>
					<ZyroLoader
						v-show="isLoading"
						class="category__suggestions-loader"
					/>
				</div>
				<div
					v-for="(category, index) in categorySuggestions"
					:key="category"
					:ref="SUGGESTION_REF"
					v-qa="`wizard-websitecategory-ddl-${category}`"
					class="category__suggestions-category z-body-small"
					:class="{
						'category__suggestions-category--active':
							activeSuggestionIndex === index + (value ? 1 : 0)
					}"
					@click="selectCategorySuggestion(category)"
					v-text="category"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import {
	computed,
	ref,
	watch,
	getCurrentInstance,
} from '@vue/composition-api';

import {
	useApi,
	WIZARD_CATEGORY_SUGGESTION,
	WIZARD_CATEGORY_SUGGESTION_LOG,
} from '@/use/useApi';
import { debounce } from '@/utils/debounce';

const SUGGESTIONS_LOAD_DEBOUNCE_RATE = 300;
const INPUT_LOG_DEBOUNCE_RATE = 2000;
const DEFAULT_SUGGESTIONS = [
	'Family Restaurant',
	'Healthy Eating Blog',
	'Sports Trainer',
	'Beauty Shop',
	'Wedding Invitation',
	'Fashion Designer',
	'Artist',
	'Music Band',
	'Budget Hotel',
];
const ARROW_UP = 'arrow-up';
const ARROW_DOWN = 'arrow-down';
const INPUT_VALUE_SUGGESTION_REF = 'input-value-suggestion';
const SUGGESTION_REF = 'suggestion';

export default {
	props: {
		value: {
			type: String,
			required: true,
		},
	},
	setup(props, {
		emit,
		refs,
	}) {
		const { $nextTick } = getCurrentInstance();
		const {
			isLoading,
			result,
			callApi: callSuggestionsApi,
		} = useApi();

		const { callApi: logInput } = useApi();

		const categorySuggestions = computed(() => (
			props.value
				? result.value?.suggestions
				|| DEFAULT_SUGGESTIONS : DEFAULT_SUGGESTIONS
		));

		const suggestionsDOMReferences = ref([]);
		const activeSuggestionIndex = ref(0);
		const suggestionsWithUserInput = computed(() => (
			props.value
				? [
					props.value,
					...categorySuggestions.value,
				]
				: categorySuggestions.value));
		const setActiveSuggestion = (arrowDirection) => {
			if (arrowDirection === ARROW_UP) {
				if (activeSuggestionIndex.value === 0) {
					activeSuggestionIndex.value = suggestionsWithUserInput.value.length - 1;
				} else {
					activeSuggestionIndex.value -= 1;
				}
			}

			if (arrowDirection === ARROW_DOWN) {
				if (activeSuggestionIndex.value === suggestionsWithUserInput.value.length - 1) {
					activeSuggestionIndex.value = 0;
				} else {
					activeSuggestionIndex.value += 1;
				}
			}

			suggestionsDOMReferences.value[activeSuggestionIndex.value].scrollIntoView({ block: 'nearest' });
		};

		const selectActiveSuggestion = () => {
			emit('input', suggestionsWithUserInput.value[activeSuggestionIndex.value]);
			emit('next-step');
		};

		const loadCategorySuggestions = (value) => callSuggestionsApi(WIZARD_CATEGORY_SUGGESTION, {
			method: 'post',
			data: { input: value },
		});

		// On init, load suggestions if value already exists
		if (props.value) {
			loadCategorySuggestions(props.value);
		}

		const loadCategorySuggestionsWithDebounce = debounce((value) => {
			loadCategorySuggestions(value);
		}, SUGGESTIONS_LOAD_DEBOUNCE_RATE);
		const selectCategorySuggestion = (category) => {
			emit('input', category);
			emit('next-step');
		};

		const logInputWithDebounce = debounce((value) => {
			logInput(WIZARD_CATEGORY_SUGGESTION_LOG, {
				method: 'post',
				data: {
					input: value,
					...result.value,
				},
			});
		}, INPUT_LOG_DEBOUNCE_RATE);

		const handleInput = (value) => {
			emit('input', value);
			// eslint-disable-next-line no-param-reassign
			refs.suggestions.scrollTop = 0;
			activeSuggestionIndex.value = 0;

			if (value) {
				loadCategorySuggestionsWithDebounce(value);
				logInputWithDebounce(value);
			}
		};

		watch(categorySuggestions, async () => {
			await $nextTick();
			suggestionsDOMReferences.value = [
				...(refs[INPUT_VALUE_SUGGESTION_REF] ? [refs[INPUT_VALUE_SUGGESTION_REF]] : []),
				...(refs[SUGGESTION_REF] ?? []),
			];
		});

		return {
			ARROW_UP,
			ARROW_DOWN,
			INPUT_VALUE_SUGGESTION_REF,
			SUGGESTION_REF,
			categorySuggestions,
			selectCategorySuggestion,
			isLoading,
			handleInput,
			activeSuggestionIndex,
			setActiveSuggestion,
			suggestionsDOMRefs: suggestionsDOMReferences,
			selectActiveSuggestion,
		};
	},
};
</script>

<style lang="scss" scoped>
.category {
	width: 100%;
	max-width: 446px;
	padding-top: 72px;

	&__input {
		margin-bottom: 8px;
	}

	&__suggestions {
		width: 100%;
		max-height: 258px;
		padding-top: 8px;
		overflow-y: auto;
		border-radius: $border-radius-small;
		box-shadow: 0 6px 14px rgba(0, 0, 0, 0.1);
	}

	&__suggestions-loader {
		position: absolute;
		top: 12px;
		right: 20px;
		margin: 0;

		--size: 12px;
		--weight: 2px;
		--border: 1px;
	}

	&__suggestions-loader-wrapper {
		display: flex;
		justify-content: center;
		width: 100%;
		margin-top: 20px;
	}

	&__suggestions-title {
		position: relative;
		padding: 10px 20px;
		color: $grey-800;
	}

	&__suggestions-category {
		padding: 10px 20px;
		transition: background-color 0.2s ease;

		&--active {
			background: $primary-lighter;
			transition: none;
		}

		&:hover {
			cursor: pointer;
			background: $primary-lighter;
		}
	}
}
</style>
