<template>
	<div class="wizard-preview">
		<ZyroLoader v-if="isOpeningUserSite" />
		<template v-else>
			<ZyroButton
				v-if="wizardType === WIZARD_TYPE_GENERATOR"
				theme="primary"
				color="blue"
				data-qa="wizard-btn-generateagain"
				@click="$emit('final-step-action')"
			>
				{{ $t('wizard.selectWebsite.generateAgain') }}
			</ZyroButton>
			<ZyroButton
				v-else
				theme="primary"
				@click="$emit('select-website', 0)"
			>
				{{ $t('wizard.selectImportedWebsite.openWebsite') }}
			</ZyroButton>
		</template>
		<div
			v-if="websites"
			class="wizard-preview__websites"
			:style="siteCSSVariables"
		>
			<div
				v-for="(website, index) in websites"
				:ref="`website-${index}`"
				:key="`website-${index}`"
				class="wizard-preview__website"
				:class="{ 'wizard-preview__website--disabled': wizardType === WIZARD_TYPE_IMPORTER }"
			>
				<div
					class="wizard-preview__select"
					@click="$emit('select-website', index)"
				>
					<ZyroButton
						icon-right="arrow-right-slim"
						color="blue"
						class="wizard-preview__select-button"
						data-qa="wizard-btn-choosethisdesign"
					>
						{{ $t('wizard.selectWebsite.choose') }}
					</ZyroButton>
					<div
						class="wizard-preview__website-wrapper"
						data-qa="wizard-card-sitepreview"
					>
						<ZyroBlockPreview
							v-for="(block, blockId) in website.blocks"
							:key="blockId"
							:block="block"
							:block-id="blockId"
							:components="website.components"
							:preview-width="websitePreviewWidth"
							:actual-width="screenWidth"
							class="wizard-preview__block-preview"
						/>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { getCurrentInstance } from '@vue/composition-api';

import {
	WIZARD_TYPE_GENERATOR,
	WIZARD_TYPE_IMPORTER,
} from '@/pages/wizard/data/constants';
import { useSiteStyles } from '@/use/useSiteStyles';

export default {
	props: {
		wizardWebsites: {
			type: Array,
			required: true,
		},
		wizardType: {
			type: String,
			required: true,
		},
		isOpeningUserSite: {
			type: Boolean,
			default: false,
		},
	},
	setup() {
		const { siteCSSVariables } = useSiteStyles();
		const { $intercom } = getCurrentInstance();

		$intercom.show();

		return {
			siteCSSVariables,
			WIZARD_TYPE_GENERATOR,
			WIZARD_TYPE_IMPORTER,
		};
	},
	data() {
		return {
			websitePreviewWidth: 0,
			screenWidth: window.innerWidth,
		};
	},
	computed: {
		websites() {
			if (this.wizardWebsites.length === 0) {
				return null;
			}

			return this.wizardWebsites.map((website) => {
				const {
					meta,
					pages,
					blocks,
					components,
				} = website;

				const homepageBlocks = pages[meta.homepageId].blocks.reduce((accumulator, item) => ({
					...accumulator,
					[item]: blocks[item],
				}), {});

				const [footerBlockId, footerBlockValue] = Object.entries(blocks).find(([, block]) => block.slot === 'footer') ?? [];
				const footerBlock = footerBlockId && footerBlockValue
					? { [footerBlockId]: footerBlockValue } : {};

				return {
					blocks: {
						...homepageBlocks,
						...footerBlock,
					},
					components,
				};
			});
		},
	},
	watch: {
		async wizardWebsites() {
			await this.$nextTick();
			this.websitePreviewWidth = this.$refs['website-0'][0].offsetWidth;
		},
	},
	created() {
		this.$emit('final-step-action');
	},
};
</script>

<style lang="scss" scoped>
.wizard-preview {
	$this: &;

	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	padding: 30px 0 100px 0;

	&__websites {
		display: flex;
		justify-content: space-between;
		width: 100%;
		max-width: 1134px;
		padding-top: 24px;

		@media screen and (max-width: 1200px) {
			max-width: 95%;
		}
	}

	&__website {
		width: 30%;
		margin: 0 auto;

		&--disabled {
			pointer-events: none;
		}
	}

	&__select {
		display: flex;
		flex-direction: column;
		align-items: center;
		transition: transform 0.3s;
		transform-origin: 50% 10%;

		&:hover {
			cursor: pointer;
			transform: scale(1.1);
		}
	}

	&__select-button {
		color: $accent-two;
		opacity: 0;
		transition: 0.2s;
	}

	#{$this}__select:hover &__select-button {
		opacity: 1;
	}

	&__website-wrapper {
		width: 100%;
		overflow: hidden;
		border: 1px solid $grey-200;
	}
}

.button {
	margin-bottom: 20px;
}
</style>
