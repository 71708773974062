<template>
	<WizardBase
		:subtitle="subtitle"
		:title="title"
		:loader-type="loaderType"
		:loader-message="$t('wizard.loader.generator')"
		:show-back="canGoBack"
		:show-skip="canSkip"
		:show-continue="canContinue"
		:is-navigation-fixed-bottom="isNavigationFixedBottom"
		:current-step="currentStep"
		@back="goBackward"
		@skip="skip"
		@continue="goForward"
	>
		<Component
			:is="currentStepComponent"
			:value="currentStepPropertyValue"
			:wizard-websites="generatedWebsites"
			:wizard-type="WIZARD_TYPE_GENERATOR"
			@input="setCurrentStepPropertyValue"
			@next-step="goForward"
			@final-step-action="generateWebsites"
			@select-website="selectWebsite"
		/>
	</WizardBase>
</template>

<script>
/* eslint no-console: ["warn", { allow: ["error"] }] */
import { nanoid } from 'nanoid';
import {
	mapMutations,
	mapActions,
	mapState,
} from 'vuex';

import EventLogApi from '@/api/EventLogApi';
import { createSite } from '@/api/SitesApi';
import { getRandom } from '@/api/UnsplashApi';
import addBlockTemplate from '@/data/AddBlockModalTemplate.json';
import baseTemplate from '@/data/BaseTemplate.json';
import WizardBase from '@/pages/wizard/WizardBase.vue';
import WizardPreview from '@/pages/wizard/WizardPreview.vue';
import {
	GENERATED_SITE_NAME,
	GENERATED_SITE_TEMPLATE_ID,
	LOADER_TYPES,
	WIZARD_TYPE_GENERATOR,
} from '@/pages/wizard/data/constants';
import {
	ADD_CONDITIONS,
	ADD_BLOCK_PAGES,
	ITEM_TYPES,
	GENERATOR_ITEMS,
} from '@/pages/wizard/data/features';
import {
	PROPERTIES,
	GENERATOR_STEPS,
	DEFAULT_VALUES,
} from '@/pages/wizard/data/steps';
import WizardCategoryInput from '@/pages/wizard/generator/GeneratorCategoryInput.vue';
import WizardFeaturesSelect from '@/pages/wizard/generator/GeneratorFeaturesSelect.vue';
import { useStep } from '@/pages/wizard/use/useStep';
import { BUILDER_ROUTE } from '@/router';
import {
	mapActionsNotifications,
	NOTIFY,
} from '@/store/builder/notifications';
import { getRandomArrayItem } from '@/utils/array';
import { asyncForEach } from '@/utils/asyncForEach';
import { cloneDeep } from '@/utils/object';

const DEFAULT_GENERATED_WEBSITES_COUNT = 3;
const GENERATOR_LOADER_DELAY = 3000;
const GRID_IMAGE = 'GridImage';
const GRID_INSTAGRAM = 'GridInstagramFeed';

export default {
	components: {
		WizardBase,
		WizardCategoryInput,
		WizardFeaturesSelect,
		WizardPreview,
	},
	metaInfo() {
		return { title: 'Zyro Website Generator' };
	},
	setup() {
		const {
			stepState,
			loaderType,
			currentStep,
			currentStepData,
			currentStepComponent,
			currentStepPropertyValue,
			canGoBack,
			canContinue,
			canSkip,
			subtitle,
			title,
			isNavigationFixedBottom,
			setActiveStep,
			setLoaderType,
			setCurrentStepPropertyValue,
			goBackward,
			goForward,
			skip,
		} = useStep({
			steps: GENERATOR_STEPS,
			stepState: {
				category: DEFAULT_VALUES[PROPERTIES.CATEGORY],
				selectedItems: DEFAULT_VALUES[PROPERTIES.SELECTED_ITEMS],
			},
		});

		return {
			stepState,
			loaderType,
			currentStep,
			currentStepData,
			currentStepComponent,
			currentStepPropertyValue,
			canGoBack,
			canContinue,
			canSkip,
			subtitle,
			title,
			isNavigationFixedBottom,
			setActiveStep,
			setLoaderType,
			setCurrentStepPropertyValue,
			goBackward,
			goForward,
			skip,
			WIZARD_TYPE_GENERATOR,
		};
	},
	data() {
		return { generatedWebsites: [] };
	},
	computed: { ...mapState(['website']) },
	mounted() {
		this.initializeWizard();
	},
	methods: {
		...mapMutations([
			'setWebsite',
			'setElementData',
			'setBlockData',
			'setHasBuilderInitialized',
		]),
		...mapActions(['addBlock']),
		...mapActions('pages', [
			'addPage',
			'setCurrentPage',
		]),
		...mapActionsNotifications({ notify: NOTIFY }),
		async generateWebsites(count = DEFAULT_GENERATED_WEBSITES_COUNT) {
			/**
			 * If builder was initialized, after this function it will need to reinitialize
			 * as this function will modify vuex state
			 */
			this.setHasBuilderInitialized(false);

			this.loaderType = LOADER_TYPES.GENERATOR;

			const generatedSites = [];

			await asyncForEach([...new Array(count)], async () => {
				const site = await this.generateWebsite();

				generatedSites.push(site);
			});
			this.generatedWebsites = generatedSites;

			// After rendering sites, delay a loader reset a bit, so most/all images gets loaded
			setTimeout(() => { this.loaderType = ''; }, GENERATOR_LOADER_DELAY);
		},
		async generateWebsite() {
			// Reset vuex state
			this.setWebsite({
				website: cloneDeep(baseTemplate),
				websiteId: null,
			});
			this.setCurrentPage({ type: 'default' });

			let previousBlockId = null;

			await asyncForEach(GENERATOR_ITEMS, async ({
				type,
				addCondition,
				addPageType,
				addBlockTemplatePage,
				fallback,
			}) => {
				try {
					const isSelectedFeature = addCondition === ADD_CONDITIONS.IF_SELECTED
						&& this.stepState.selectedItems.includes(type);

					if (addCondition !== ADD_CONDITIONS.ALWAYS && !isSelectedFeature) {
						if (fallback) {
							previousBlockId = this.addRandomProvidedPageBlock(
								fallback.addBlockTemplatePage, previousBlockId, fallback.type,
							);
						}

						return;
					}

					if (addBlockTemplatePage) {
						previousBlockId = this.addRandomProvidedPageBlock(
							addBlockTemplatePage, previousBlockId, type,
						);
					} else if (addPageType) {
						await this.addPage({ type: addPageType });
					}
				} catch {
					this.notify({
						message: `Failed to add element of type - ${type || addPageType} while generating a website`,
						origin: 'Generator',
					});
				}
			});

			await this.seedImages();

			const generatedWebsite = cloneDeep(this.website);

			return generatedWebsite;
		},
		/*
		 *  Adds random provided type block from addBlockTemplate
		 *  addBlockTemplate pages are block TYPES (ex. "about" page will have only "about" type blocks)
		 */
		addRandomProvidedPageBlock(blockPage, previousBlockId, blockType) {
			const filterBlocks = (block) => {
				if (typeof block !== 'object') {
					return false;
				}

				if (this.blockPage === ADD_BLOCK_PAGES.FOOTER) {
					if (this.blockType === ITEM_TYPES.FOOTER_CONTACT) {
						return block.components.some(
							(component) => addBlockTemplate.components[component].formId === 'contactForm',
						);
					}

					return block.components.every(
						(component) => !addBlockTemplate.components[component].formId,
					);
				}

				return true;
			};

			// Map provided page blocks from addBlockTemplate, filter out possible undefined values
			const blocks = addBlockTemplate.pages[blockPage].blocks
				.map((pageBlock) => addBlockTemplate.blocks[pageBlock])
				.filter(filterBlocks, {
					blockPage,
					blockType,
				});

			const blockToAdd = getRandomArrayItem(blocks);
			const blockToAddComponents = blockToAdd.components.map(
				(component) => addBlockTemplate.components[component],
			);
			const slot = blockPage === ADD_BLOCK_PAGES.FOOTER ? 'footer' : undefined;

			const blockAndComponents = this.prepareBlockToAddAction(
				blockToAdd, blockToAddComponents, slot,
			);

			this.addBlock({
				previousBlockId,
				...blockAndComponents,
				addToPage: slot !== 'footer',
			});

			return blockAndComponents.blockId;
		},
		prepareBlockToAddAction(block, components, slot) {
			const componentsObject = components.reduce((accumulator, item) => ({
				...accumulator,
				[nanoid()]: item,
			}), {});
			const componentsIdsArray = Object.keys(componentsObject);

			return {
				blockId: nanoid(),
				block: {
					...block,
					components: componentsIdsArray,
					zindexes: [...componentsIdsArray],
					slot,
				},
				components: componentsObject,
			};
		},
		async selectWebsite(index) {
			try {
				await createSite({
					data: this.generatedWebsites[index],
					siteName: GENERATED_SITE_NAME,
					templateId: GENERATED_SITE_TEMPLATE_ID,
				});
			} catch (error) {
				console.error(error);
				this.notify({
					message: 'Failed to create a website. Try again.',
					origin: 'Generator',
				});

				return;
			}

			this.setCurrentPage({ type: 'default' });
			this.$router.push({ name: BUILDER_ROUTE });
			EventLogApi.logEvent({
				eventName: 'qa_wizard.website_ready.select_variation',
				variation_num: index,
			});
		},
		initializeWizard() {
			const eventName = 'qa_wizard.welcome_screen.generator';

			EventLogApi.logEvent({ eventName });
			this.$intercom.hide();
			window.hj('identify', null, { [eventName]: true });
		},
		async seedImages() {
			try {
				const {
					blocks,
					components,
				} = this.website;
				const imagesTheme = this.stepState.category || null;
				const blocksWithBackgroundImageIds = this.getBlocksWithBackgroundImageIds(blocks);
				const imageComponentsIds = this.getComponentsIdsByType(components, GRID_IMAGE);
				const instagramComponentsIds = this.getComponentsIdsByType(components, GRID_INSTAGRAM);

				const { results } = await getRandom(imagesTheme ? { query: imagesTheme } : {});

				imageComponentsIds.forEach((id) => {
					this.setElementData({
						elementId: id,
						data: { settings: { image: this.getRandomUnsplashImage(results) } },
					});
				});

				blocksWithBackgroundImageIds.forEach((id) => {
					this.setBlockData({
						blockId: id,
						data: { background: { image: this.getRandomUnsplashImage(results) } },
					});
				});

				if (imagesTheme) {
					instagramComponentsIds.forEach((id) => {
						this.setElementData({
							elementId: id,
							data: { settings: { randomImagesTheme: imagesTheme } },
						});
					});
				}
			} catch {
				this.notify({
					message: 'Error while adding thematic images',
					origin: 'Generator',
				});
			}
		},
		getComponentsIdsByType(allComponentsObject, type) {
			return Object.keys(allComponentsObject).filter((id) => allComponentsObject[id].type === type);
		},
		getBlocksWithBackgroundImageIds(allBlocks) {
			return Object.keys(allBlocks).filter((id) => allBlocks[id].background.current === 'image');
		},
		getRandomUnsplashImage(unsplashImagesObjectArray) {
			return getRandomArrayItem(unsplashImagesObjectArray).urls.regular;
		},
	},
};
</script>
